.layout {
  display: flex;
}
main {
  width: 100%;
  color: #ccc;
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 240px;
  height: 100vh;
  flex-shrink: 0;
  background-color: #ffffff;
  background-overflow: auto;
  transition: all 0.5s;
  border-right: 2px dashed rgba(152, 152, 152, 0.1);

  /* box-shadow: 8px 0 20px rgb(240, 240, 240); */
}
.top_section {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 10px 15px;
  transition: all 0.5s;
  background-color: #ffffff;
  height: 79.6px;
  margin-top: 2rem;
  margin-bottom: 8rem;
  /* border-right: 0px solid rgba(152, 152, 152, 0.1); */
  /* border-right: none !important; */
}
.logo {
  box-sizing: border-box;
  font-size: 30px;
  color: var(--color-white);
  margin-top: 1.5rem;
  padding-left: 4rem;
}
.logo-icon {
}
.bars {
  display: flex;
  font-size: 25px;
  cursor: pointer;

  color: var(--dark-blue);
  transition: all 0.2s;
}
.bars:hover {
  color: var(--color-primary);
  /* font-size: 27px; */
}
.sidebar-item {
  /* color: rgb(170, 170, 170); */
  padding: 0.75em;
  display: block;
  transition: background-color 0.15s;
  border-radius: 6px;

  /* box-shadow: inset 1px -1px 3px 0px; */
}
.sidebar-item:hover {
  background-color: rgba(137, 137, 137, 0.1);
  /* transition: all 0.1s; */
}
.sidebar-title {
  display: flex;
  cursor: pointer;
  font-size: 1.2em;
  justify-content: space-between;
  align-items: center;
}
.sidebar-title span {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  transition: all 0.5s;
  font-size: 1.6rem;
  margin: 0.3rem;
}
.sidebar-title span .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5em;
  font-size: 23px;
}
.sidebar-title .arrow-icon {
  cursor: pointer;
  transition: all 0.5s;
}
.sidebar-item.open > .sidebar-title .arrow-icon {
  transform: rotate(90deg);
}
.sidebar-content {
  padding-top: 0.25em;
  height: 1px;
  overflow: hidden;
}
.sidebar-item.open > .sidebar-content {
  height: auto;
}
.s-child {
  margin-top: 5px;
}
.s-child:hover {
  /* border: 1px solid var(--light-blue); */
  border-radius: 6px;
}
.side-item {
  margin: 6px;
  border-radius: 200px;
}

a {
  color: rgb(3, 4, 28);
  text-decoration: none;
  /* transition: all 0.2s; */
}
.active {
  color: rgb(255, 255, 255);
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 6px;
  background-color: var(--light-blue);
  transition: background-color 0.5s;
  /* background-color: #2196f3; */
}
.sublink .sidebar-item .sidebar-title {
  padding-left: 40px;
}
