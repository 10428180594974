.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: 0 8px 8px rgb(230, 230, 230); */
  background-color: rgb(255, 255, 255);
  margin-left: -1.4rem;
  padding-left: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 0;
  z-index: 3;
}

.header h3 {
  margin-left: 2rem;
}

.header-btn-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 2rem;
}

.header-btn {
  padding: 1rem 1.5rem;
}
