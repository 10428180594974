.navbar-class {
  box-shadow: 0 8px 8px rgb(230, 230, 230);
  background-color: rgb(255, 255, 255);
}

.navbar-logo {
  margin: 1rem 0rem 1rem 0rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
