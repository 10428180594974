.info-box {
  width: 100%;
  height: 9rem;
  max-width: 22rem;
  /* margin-right: 1rem; */
  /* margin-bottom: 1rem; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
  flex-wrap: wrap;
  color: #090129;
  transform: translateY(0);
  transition: all 0.3s;
  border-radius: 6px;
  white-space: nowrap;
  overflow: hidden;
  box-shadow: 0 0 4px rgb(225, 225, 225);
}
.info-box:hover {
  cursor: pointer;
  transform: translateY(-5px);
}
.info-box .info-icon {
  padding: 0 2rem;
  color: #04011c;
}
.info-icon svg {
  color: rgb(68, 68, 68) !important;
}
.info-box .info-text > * {
  color: #01011a;
}
@media screen and (max-width: 600px) {
  .info-box {
    max-width: 100%;
  }
}
