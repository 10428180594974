.main-container {
  display: flex;
  justify-content: space-around;
}
.users-container {
  min-width: 550px;

  overflow-y: auto;
  margin-top: 4rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0 8px rgb(211, 211, 211);
  border-radius: 8px;
}

.profile-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
  margin: 1rem 2rem 1rem 2rem;
  box-shadow: 0 0 4px rgb(211, 211, 211);
  border-radius: 4px;

  /* border: 1px solid gray; */
  /* border: 2px solid red; */
}
.view {
  color: white;
}

.profile-container img {
  border-radius: 100%;
  width: 70px;
}

.profile-container p {
  font-size: 1.8rem;
  letter-spacing: 1px;
  color: rgb(124, 123, 123);
}

.share {
  width: 500px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-content: space-between;
  overflow-y: auto;
  margin-top: 4rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0 8px rgb(211, 211, 211);
  border-radius: 8px;
}
.share-btn {
  padding: 0.75rem;
}
.share p {
  padding: 1rem;
  letter-spacing: 1px;
  color: rgb(173, 173, 173);
}
.add-user-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard {
  width: 500px;
  height: 400px;

  overflow-y: auto;
  margin-top: 4rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0 8px rgb(211, 211, 211);
  border-radius: 8px;
}

.buttons {
  display: flex;
}
