.contact {
  display: flex;
  flex-direction: column;
}
.contactTop {
  text-align: center;
  padding-top: 2rem;
}
.section {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
}

.formDiv {
  display: flex;
  width: 100%;
  justify-content: center;
}
.detailsDiv {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 2rem;
}

.contact .card1 {
  width: 100%;

  border-radius: 8px;
  box-shadow: 0 0 12px rgb(225, 225, 225);
  background-color: white;
  padding: 2rem 3rem;
}

.contact .card2 {
  padding: 2rem 4.3rem;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 0 12px rgb(225, 225, 225);
  background-color: white;
  color: #1a1a1a;
}
.contact .card2 h3,
.contact .card2 p {
  color: #434343;
}
.contact .card2 .icons {
  margin: 3rem 0;
}
.contact .card2 .icons span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  color: rgb(2, 4, 34);
}
.contact .card2 .icons span a,
.contact .card2 .icons span p {
  margin-left: 5px;
}
.contact .section {
  display: flex;
  align-items: flex-start;
}
.contact .section form {
  width: 500px;
  max-width: 100%;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 2rem;
}
.contact .section form label {
  display: block;
  font-size: 1.4rem;
  font-weight: 500;
  color: rgb(35, 34, 34);
}
.contact .section form input[type="text"],
.contact .section form input[type="number"],
.contact .section form input[type="file"],
.contact .section form input[type="email"],
.contact .section form select,
.contact .section form textarea,
.contact .section form input[type="password"] {
  display: block;
  background: transparent;
  font-size: 1.6rem;
  color: rgb(20, 19, 19);
  font-weight: 300;
  padding: 1rem;
  margin: 1rem auto;
  width: 100%;
  max-width: 100%;
  border: 1px solid #202020;
  border-radius: 3px;
  outline: none;
}
@media screen and (max-width: 700px) {
  .contact .section {
    flex-direction: column;
  }
}
