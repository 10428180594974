
.assign-btn{
 
    font-size: 2.3rem;
    cursor: pointer;
    padding: 1rem 2rem;
}

.assign-form input,select{
   padding: 1rem;
   color: black;

   margin:  0.8rem auto;
   width: 100%;
  
}



/* .assign-form  input::placeholder{
    color: rgb(124, 124, 124);
} */

.cancel-confirm{
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem auto;
} 
/* .cancel , .confirm{
    padding: 1rem;
    font-size: 2rem;
    border: 1px solid gray;
    border-radius: 0.3rem; */
    /* margin: 0.6rem ; */
    /* cursor: pointer; */
    
    
/* } */ 

 .cancel{
  
padding: 0.8rem 1rem;
}

 .cancel:hover{
    background-color: rgb(240, 49, 49);
    color: white;
 
} 

.confirm {
    background-color: var(--dark-blue);
    padding: 0.8rem 1rem;
    color: rgb(219, 219, 219);
} 

.confirm:hover {
    

}

input#date {
    display: inline-block;
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.search-result{
    color: rgba(54, 54, 54, 0.5);
 
   position: absolute;
 
   background-color: rgb(255, 255, 255);
   z-index: 3;
   box-sizing: border-box;
   margin: 0;
   box-shadow: 0 0 2px white;
 
 
 
    
}

.user{
  
    color: rgb(178, 178, 178),;
   
    font-size: 1.4rem;
    box-shadow: 0 0 4px rgb(255, 255, 255);
    margin-top: 0.3rem;
    border: 1px solid rgba(160, 160, 160, 0.5);
    border-radius: 0.3rem;
    background-color: white;
    width: 102%;
    box-sizing: border-box;
 
    transition: 200ms ease;
    padding: 0.4rem;
}
.user p{}

.user:hover{
    box-sizing: border-box;
    cursor: pointer;
    box-shadow: 0 0 4px rgb(206, 206, 206);
    transition: 200ms ease;
    color :rgb(41, 40, 40);
    border:1px solid rgba(9, 15, 1,0.5);
}


/* FORM STYLE */

.form-assign {
    width: 35rem;
   
 
    background-color: #ffffff;

    box-shadow: 0 0 2px rgb(225, 225, 225);
    padding: 5rem;
    border-radius: 4px;
}

.form-assign h2 {
    color: black;
    text-align: center;
}

.form-assign form input[type="text"],
.form-assign form input[type="email"],
.form-assign form input[type="password"] {
    display: block;
    font-size: 1.6rem;
    font-weight: 300;
    padding: 1rem;
    margin: 1.5rem auto;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
}

.form-assign form input[type="text"]:focus,
.form-assign form input[type="email"]:focus,
.form-assign form input[type="password"]:focus {
    outline: none;
    border: 1px solid black;
}

.form-assign form input[type="text"]:focus:invalid,
.form-assign form input[type="email"]:focus:invalid,
.form form input[type="password"]:focus:invalid {}

.form-assign form input[type="text"]::placeholder,
.form-assign form input[type="email"]::placeholder,
.form-assign form input[type="password"]::placeholder {
    color: black;
}

.form-assign form .links {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

.form-assign form p {
    text-align: center;
    margin: 1rem;

}

.form-assign .register {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.form-assign label{
    font-size: 1.5rem;
    color:rgb(63, 63, 63);
}



@keyframes slide-up {
    0% {
        transform: translateY(-5rem);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slide-down {
    0% {
        transform: translateY(5rem);
    }

    100% {
        transform: translateY(0);
    }
}

@media screen and (max-width: 700px) {
    .img {
        display: none;
    }
}

.save-btn {
    margin-top: 2rem;
    padding: 1.5rem 3rem;
}