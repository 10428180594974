/* HOME DUPLICATE */

.home {
  background-image: url("/public/Circuit_Board.svg");
  min-height: 100vh;
  color: #0d1117;
  background-repeat: no-repeat;

  background-position: center;
}

.home nav {
  color: #131524;
  width: 100%;
  box-shadow: 0 0 8px 3px rgb(230, 230, 230);
  background-color: rgb(255, 255, 255);
}

.home nav > .logo {
  color: #ffffff;
}

.home-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-links li a {
  color: rgba(255, 255, 255);
  font-weight: bold;
  font-size: 1.5rem;
  padding: 0.4rem 1rem;
}

.home-links > * {
  margin-left: 1rem;
  color: #00378a;
}

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin-top: 3rem;
  box-shadow: 0 0 12px rgb(225, 225, 225);
  padding: 2rem;
  border-radius: 8px;
}

.hero-text .hero-buttons a {
  color: #0d1117;
}

.hero-image {
  width: 100%;
}

.hero-text > h2 {
  font-family: "Inter", sans-serif;
  font-size: 4rem;
  letter-spacing: 1px;
  color: #02082b;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .hero {
    flex-direction: column;
  }

  .hero-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: auto;
    text-align: center;
  }

  .hero-image {
    width: 100%;
  }

  .hero-imageimg {
    width: 100%;
  }
}
/* HOME DUPLICATE END */

/* //animation */
