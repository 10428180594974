.auth {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth .img {
  animation: slide-down 0.5s ease;
}
.auth .form {
  width: 35rem;
  padding: 30px;
  border-radius: 1px;
  padding: 1.5rem;
  background-color: #ffffff;

  box-shadow: 0 0 12px rgb(225, 225, 225);
    padding: 2rem;
    border-radius: 8px;
}
.auth .form h2 {
  color: black;
  text-align: center;
}
.auth .form form input[type="text"],
.auth .form form input[type="email"],
.auth .form form input[type="password"] {
  display: block;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 1rem;
  margin: 3rem auto;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 999px;
  outline: none;
}
.auth .form form input[type="text"]:focus,
.auth .form form input[type="email"]:focus,
.auth .form form input[type="password"]:focus {
  outline: none;
  border: 1px solid black;
}
.auth .form form input[type="text"]:focus:invalid,
.auth .form form input[type="email"]:focus:invalid,
.auth .form form input[type="password"]:focus:invalid {
}
.auth .form form input[type="text"]::placeholder,
.auth .form form input[type="email"]::placeholder,
.auth .form form input[type="password"]::placeholder {
  color:black;
}

.auth .form form .links {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}
.auth .form form p {
  text-align: center;
  margin: 1rem;
}
.auth .form .register {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
@keyframes slide-up {
  0% {
    transform: translateY(-5rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-down {
  0% {
    transform: translateY(5rem);
  }
  100% {
    transform: translateY(0);
  }
}
@media screen and (max-width: 700px) {
  .img {
    display: none;
  }
}


/* custom style */
.auth {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth .img {
  animation: slide-down 0.5s ease;
}

.auth .form {
  width: 40rem;
  padding: 30px;
  border-radius: 1px;
  padding: 1.5rem;
  background-color: #ffffff;

  box-shadow: 0 0 12px rgb(225, 225, 225);
  padding: 5rem;
  border-radius: 8px;
}

.auth .form h2 {
  color: black;
  text-align: center;
}

.auth .form form input[type="text"],
.auth .form form input[type="email"],
.auth .form form input[type="password"] {
  display: block;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 1rem;
  margin: 1.5rem auto;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.auth .form form input[type="text"]:focus,
.auth .form form input[type="email"]:focus,
.auth .form form input[type="password"]:focus {
  outline: none;
  border: 1px solid black;
}

.auth .form form input[type="text"]:focus:invalid,
.auth .form form input[type="email"]:focus:invalid,
.auth .form form input[type="password"]:focus:invalid {}

.auth .form form input[type="text"]::placeholder,
.auth .form form input[type="email"]::placeholder,
.auth .form form input[type="password"]::placeholder {
  color: black;
}

.auth .form form .links {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.auth .form form p {
  text-align: center;
  margin: 1rem;
  
}

.auth .form .register {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}



@keyframes slide-up {
  0% {
    transform: translateY(-5rem);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(5rem);
  }

  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width: 700px) {
  .img {
    display: none;
  }
}