.product-details-box {
  box-sizing: border-box;
  margin-top: 3rem;
}

.product-detail {
  display: flex;
  justify-content: space-around;
}
.consume-assign-table {
  display: flex;
  flex-direction: column;
  min-width: 600px;
  margin-left: 2rem;
}

.assign-table,
.consume-table {
  background-color: white;
  box-shadow: 0 0 12px rgb(225, 225, 225);
  border-radius: 8px;
  padding: 2rem 2rem 1rem 2rem;
  display: flex;
  justify-content: center;
}
.assign-table table,
.consume-table table {
  min-width: 600px;
  text-align: center;
  color: rgb(66, 66, 66);
  border-collapse: collapse;
  font-size: 1.4rem;
}

.assign-table table th,
.consume-table table th {
  text-align: center;
  border: 1px solid #eee;
  color: black;
}
.assign-table table th,
.assign-table table td,
.consume-table table th,
.consume-table table td {
  vertical-align: center;
  text-align: center;
  height: 4rem;
  transition: 200ms ease;
}
.assign-table table th.icons,
.assign-table table td.icons,
.consume-table table th.icons,
.consume-table table td.icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.assign-table table th.icons > *,
.assign-table table td.icons > *,
.consume-table table th.icons > *,
.consume-table table td.icons > * {
  margin-right: 12px;
  cursor: pointer;
  vertical-align: middle;
  align-self: center;
}
.assign-table table tr,
.consume-table table tr {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

/* .assign-table table tr:nth-child(even) {
  background-color: red;
} */
.assign-table table tbody tr:hover,
.consume-table table tbody tr:hover {
  cursor: pointer;
  background-color: rgba(60, 183, 255, 0.3);
  color: rgb(2, 2, 20);
  box-shadow: 0 0 4px rgb(231, 231, 231);
  transition: 200ms ease;
}

.consume-table {
  margin-top: 2rem;
  font-size: 1.4rem;
}

.fixed_header {
  table-layout: fixed;
  border-collapse: collapse;
  overflow: auto;
}
.fixed_header tbody {
  display: block;

  overflow: auto;
  height: 250px;
}
.fixed_header thead tr {
  display: block;
}
.fixed_header thead {
}
.fixed_header th,
.fixed_header td {
  text-align: center;
  width: 200px;
}

.product-detail .card {
  width: 100%;
  max-width: 440px;
  padding: 1rem;
  box-shadow: 0 0 12px rgb(225, 225, 225);
  border-radius: 8px;
}
.product-detail .detail > * {
  margin-bottom: 1rem;
}
.product-detail .detail .badge {
  background-color: var(--color-danger);
  color: #fff;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 3px;
}
.product-detail .detail p b {
  color: var(--dark-blue);
}
.group {
  border: 1px solid var(--dark-blue);
  padding: 5px;
  border-radius: 20px;
}

.return-btn {
  box-sizing: border-box;
  margin: 0;
  transition: 200ms linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

.return-btn:hover {
}
.return-form-class {
  position: relative;
}
.return-form {
  position: relative;
}
.return-form h4 {
  color: rgb(10, 1, 1);
}
/* FORM STYLE */

.return-form {
  width: 35rem;

  background-color: #ffffff;

  box-shadow: 0 0 2px rgb(225, 225, 225);
  padding: 5rem;
  border-radius: 4px;
}

.return-form h2 {
  color: black;
  text-align: center;
}

.return-form form input[type="text"],
.return-form form input[type="email"],
.return-form form input[type="password"] {
  display: block;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 1rem;
  margin: 1.5rem auto;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
}

.return-form form input[type="text"]:focus,
.return-form form input[type="email"]:focus,
.return-form form input[type="password"]:focus {
  outline: none;
  border: 1px solid black;
}

.return-form form input[type="text"]:focus:invalid,
.return-form form input[type="email"]:focus:invalid,
.form form input[type="password"]:focus:invalid {
}

.return-form form input[type="text"]::placeholder,
.return-form form input[type="email"]::placeholder,
.return-form form input[type="password"]::placeholder {
  color: black;
}

.return-form form .links {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.return-form form p {
  text-align: center;
  margin: 1rem;
}

.return-form .register {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.return-form label {
  font-size: 1.4rem;
  color: rgb(66, 65, 65);
}

.assign-form p {
  margin-bottom: 1rem;
  color: #424242;
  letter-spacing: 0.1rem;
}

.assign-form span {
  color: var(--dark-blue);
}

@keyframes slide-up {
  0% {
    transform: translateY(-5rem);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(5rem);
  }

  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width: 700px) {
  .img {
    display: none;
  }
}

.save-btn {
  margin-top: 2rem;
  padding: 1.5rem 3rem;
}
