.change-password {
  width: 300px;
  box-shadow: 0 0 12px rgb(225, 225, 225);
  margin-top: 2rem;
  padding: 2rem 2rem;
  border-radius: 8px;
}
.change-password .password-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.change-password .password-card form {
  display: flex;
  flex-direction: column;
}
.newPasswordInput input {
  margin-top: 1rem;
  padding: 8px 1rem;
  border-radius: 4px;
  /* /* outline: none; */

  border: 1px solid black;
}
.newPasswordInput input::placeholder {
  color: black;
}
