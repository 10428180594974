.product-summary {
  /* width: 100%; */
  /* margin-top: 2rem; */
}
.product-summary > h3 {
  text-align: center;
}
.product-summary .info-summary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.card {
  /* padding: 5px; */
  background-color: #ffffff;
}
.card1 {
  background-color: #ffffff;
}
.card2 {
  background-color: rgb(255, 255, 255);
}
.card3 {
  background-color: #ffffff;
}
.card4 {
  background-color: #ffffff;
}
.select-style {
  /* Styles for the select element */
  /* min-width: 700px; */
  /* padding: 10px; */
  font-size: 16px;
  /* border: 1px solid #ccc; */
  /* border-radius: 4px; */
}

.select-style option {
  /* Styles for the select options */
  background-color: #fff;
  color: #333;
  font-size: 14px;
}
.product-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: auto;
  max-width: 700px;
}
