.profile-div {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  align-items: flex-start;
  margin-top: 7rem;
}
.profile .card {
  max-width: 800px;

  display: flex;
  background: #ffffff;
  border: none;
  color: rgb(40, 40, 40);
  justify-content: space-evenly;

  padding: 1rem 0;
  padding-right: 4rem;
  box-shadow: 0 0 12px rgb(225, 225, 225);
  border-radius: 8px;
}
.profile .card span.profile-photo {
  text-align: center;
}
.profile .card img {
  width: 100%;
  border-radius: 999px;
  max-width: 350px;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
.profile .card span.profile-data {
  margin: 2rem 1rem;
}

.profile .card span.profile-data > * {
  border-top: 1px solid #b2b2b2;
  /* border-bottom: 1px solid #999999; */
  padding: 15px 0;
}
.profile .profile-data .saveProfileButton {
  border-bottom: none !important;
}

.profile-data input:placeholder {
  color: black;
}

.disabledInput:hover {
  cursor: not-allowed;
}
.profile-data label {
  color: rgb(96, 96, 96);
}

.profile-data p {
  text-align: initial;
  color: rgb(56, 56, 56);
}
.caption {
  border: 1px solid gray;
  font-size: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
}

@media screen and (max-width: 600px) {
  span.profile-photo {
    margin: auto;
  }
}
