.product-list {
  margin-top: 4rem;
  background-color: white;
  color: #333;
  box-shadow: 0 0 12px rgb(225, 225, 225);
  padding: 2rem;
  border-radius: 8px;
}
.product-list .table {
  color: rgba(10, 3, 39);
  /* text-align:center; */
  margin-top: 2rem;
  padding: 5px;
  width: 100%;
  overflow-x: auto;
}
.product-list .table .search {
  width: 100%;
  max-width: 300px;
}
.product-list .table table {
  border-collapse: collapse;
  width: 100%;
  font-size: 1.4rem;
}
.product-list .table table thead {
  /* border-top: 2px solid rgba(180, 176, 176, 0.5); 
   border-bottom: 2px solid rgba(182, 178, 178, 0.5); */
}
.product-list .table table th {
  text-align: center;
  border: 1px solid #eee;
}
.product-list .table table th,
.product-list .table table td {
  vertical-align: top;
  text-align: center;
  padding: 8px;
}
.product-list .table table th.icons,
.product-list .table table td.icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-list .table table th.icons > *,
.product-list .table table td.icons > * {
  margin-right: 12px;
  cursor: pointer;
  vertical-align: middle;
  align-self: center;
}
.product-list .table table tr {
  border-bottom: 1px solid #ccc;
}

/* .product-list .table table tr:nth-child(even) {
  background-color: red;
} */
.product-list .table table tbody tr:hover {
  cursor: pointer;
  background-color: rgba(31, 147, 255, 0.3);
  transition: 200ms ease;
}

.category {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.search-filter {
  display: flex;
}

.category select {
  border-radius: 999px;
  border: 1px solid #3c3b3b;
  outline: none;
  height: 40.08px;
  font-size: 1.6rem;
}
#category {
  margin-right: 2rem;
  margin-top: 1rem;
  padding-right: 3rem;
  padding-top: 0;
  padding-bottom: 0;
}

.category select option {
}
