.searchBar {
  margin: 5px 0;
  position: relative;
  flex: 1;
}
.searchIcon {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
}
.searchBar input[type="text"] {
  display: block;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 1rem;
  padding-left: 3rem;
  margin: 1rem auto;
  width: 100%;
  border: 1px solid #777;
  border-radius: 999px;
  outline: none;
}
.searchBar input[type="text"]::placeholder {
  color: black;
}
