.add-product {
  margin-bottom: 5rem;
  .card {
    // background-color: transparent;
    background-color: #f5f6fa;
    border: none;
    width: 100%;
    max-width: 500px;
    padding: 1rem;
  }
  form {
    label {
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
      color: var(--color-dark);
    }
    input[type="text"],
    input[type="number"],
    input[type="file"],
    input[type="email"],
    select,
    input[type="password"] {
      display: block;
      font-size: 1.6rem;
      font-weight: 300;
      padding: 1rem;
      margin: 1rem auto;
      width: 90%;
      border-bottom: 2px solid rgb(19, 19, 19);
      border-right: 2px solid rgb(19, 19, 19);
      border-radius: 4px;
      outline: none;
    }
  }
}

.group {
  border: 1px solid var(--dark-blue);
  padding: 5px;
}

.image-preview {
  width: 100%;
  height: 100%;
  max-height: 280px;
  background: var(--dark-blue);
  padding: 1rem;
  border-radius: 3px;
  overflow: hidden;
  // display: none;
  transition: all 0.3s;
}

.image-preview img {
  width: 100%;
}

.image-show {
  display: block;
}
.image-preview img {
    width: 100%;
}

.image-show {
    display: block;
}



/* custom style */


 .img {
    animation: slide-down 0.5s ease;
}

 .form {
    width: 56rem;
    padding: 30px;
    border-radius: 1px;
    padding: 1.5rem;
    background-color: #ffffff;

    box-shadow: 0 0 12px rgb(225, 225, 225);
    padding: 5rem;
    border-radius: 8px;
}

 .form h2 {
    color: black;
    text-align: center;
}

 .form form input[type="text"],
 .form form input[type="email"],
 .form form input[type="password"] {
    display: block;
    font-size: 1.6rem;
    font-weight: 300;
    padding: 1rem;
    margin: 1.5rem auto;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    border-bottom: 2px solid black;
        border-right: 2px solid black;
}

 .form form input[type="text"]:focus,
 .form form input[type="email"]:focus,
 .form form input[type="password"]:focus {
    outline: none;
    border: 1px solid black;
}

 .form form input[type="text"]:focus:invalid,
 .form form input[type="email"]:focus:invalid,
 .form form input[type="password"]:focus:invalid {}

 .form form input[type="text"]::placeholder,
 .form form input[type="email"]::placeholder,
 .form form input[type="password"]::placeholder {
    color: black;
}

 .form form .links {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

 .form form p {
    text-align: center;
    margin: 1rem;

}

 .form .register {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}



@keyframes slide-up {
    0% {
        transform: translateY(-5rem);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slide-down {
    0% {
        transform: translateY(5rem);
    }

    100% {
        transform: translateY(0);
    }
}

@media screen and (max-width: 700px) {
    .img {
        display: none;
    }
}

.save-btn{
    margin-top: 2rem;
    padding: 1.5rem 3rem;
}